<template>
  <div class="challenge">
    <h2>{{ title }}</h2>
    <p
      class="description"
      v-html="description"
    />
    <hr>
    <Gallery :directory="galleryFolder" />
  </div>
</template>

<script>
import Gallery from "../Gallery.vue";
import Moment from 'moment'

export default {
  name: 'Day2024',
  components: {Gallery},
  data: () => {
    return {
    }
  },
  props: [
    'day',
    'title',
    'description',
    'date',
  ],
  computed: {
    galleryFolder: function() {
      let date = new Moment(this.date, 'DD.MM.YYYY')
      return date.format('YYYY') + '/' + date.format('DD')
    }
  }
}
</script>

<style>
  .challenge {
    font-family: 'Handlee', serif;
    text-align: center;
  }

  h2 {
    font-size: 48px;
  }

  img {
    max-width: 100%;
  }

  .description {
    font-size: 24px;
  }
</style>
