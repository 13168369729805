<template>
  <Day day="07.12.2017">
    <div slot="page-content">
      <Voucher :voucher-active="buttonClicked">
        <div slot="voucherActivator">
          <p>
            <button @click="buttonClicked = true">
              <h2>
                Don't be so serious!
              </h2>
            </button>
          </p>
        </div>

        <div slot="voucher">
          <h2>Don't be so serious! </h2>
          <p>
            <img
              class="big"
              src="../../assets/days/2017/07/IMG_20150611_162432.jpg"
              v-img
            >
          </p>
          <hr>
          <Gallery directory="2017/07" />
        </div>
      </Voucher>
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'
import Voucher from '../Voucher.vue'

export default {
  name: 'Day07',
  data: function () {
    return {
      buttonClicked: false
    }
  },
  components: {
    Gallery,
    Day,
    Voucher
  }
}
</script>

<style lang="scss">
  button {
    padding: 25px 50px 20px;
    background: rgba(#fff, 0.3);
    color: white;
    border: 3px solid white;
    border-radius: 20px;

    &:hover {
      cursor: pointer;
    }
  }
</style>
