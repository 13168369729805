<template>
  <Day day="16.12.2017">
    <div slot="page-content">
      <h2>Heute bekommst Du Besuch!</h2>
      <p class="center">
        Ich freu mich schon auf Dich! ♥
      </p>
      <hr>
      <Gallery directory="2017/16" />
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'

export default {
  name: 'Day16',
  components: {
    Gallery,
    Day
  }
}
</script>
