<template>
  <Day day="20.12.2017">
    <div slot="page-content">
      <h2>Bye bye, Brüssel!</h2>
      <p class="center">
        Aber nicht traurig sein, Jana! <br>
        Wir sehen uns nämlich schon ganz bald wieder! ♥
      </p>
      <hr>
      <Gallery directory="2017/20" />
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'

export default {
  name: 'Day20',
  components: {
    Gallery,
    Day
  }
}
</script>
