<template>
  <Day day="15.12.2017">
    <div slot="page-content">
      <Voucher :voucher-active="quizFinished">
        <div slot="voucherActivator">
          <Quiz
            :data="quizData"
            @finished="quizFinished = true"
          />
        </div>

        <div slot="voucher">
          <h2>Gutschein</h2>
          <p>
            <img
              src="../../assets/days/2017/15/IMG_20160930_134603.jpg"
              class="big"
              v-img
            >
          </p>
          <p>für ein ganz ganz leckeres Eis!</p>
        </div>
      </Voucher>
    </div>
  </Day>
</template>

<script>
  import Day from '../Day.vue'
  import Voucher from '../Voucher.vue'
  import Quiz from '../Quiz.vue'

  export default {
    name: 'Day15',
    components: {
      Day,
      Voucher,
      Quiz
    },
    data () {
      return {
        voucherClicked: true,
        quizData: {
          question: 'Bei so einem heißen Wetter wie in Israel braucht man doch gleich ein...?',
          answers: [
            {answer: 'eine Kugel Eis', correct: true},
            {answer: 'noch eine Kugel Eis', correct: true},
            {answer: 'vielleicht noch eine Kugel Eis?', correct: true},
            {answer: 'oder sogar noch eine Kugel Eis!', correct: true}
          ]
        },
        quizFinished: false
      }
    }
  }
</script>
