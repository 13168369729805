<template>
  <Day day="01.12.2017">
    <div slot="page-content">
      <h3>Liebe Jana,</h3>
      <p>
        durch die aktuelle Entfernung war es mir leider nicht möglich, Dir so einen tollen Adventskalender
        wie im letzten Jahr zu schenken. Aus diesem Grund - und der Tatsache geschuldet, dass ich ganz gut
        Homepages bauen kann - habe ich mich entschieden, Dir wenigstens einen virtuellen Kalender mit ein
        paar Überraschungen zu schenken.
      </p>
      <p>
        Wir haben in den letzten drei Jahren richtig viel zusammen erlebt und sind ganz schön 'rum gekommen!
        Daran möchte ich Dich in den nächsten 24 Tagen ein wenig erinnern und gemeinsam mit Dir zurück blicken.
      </p>
      <p>
        Ich wünsche Dir viel Spaß und hoffe, du freust dich so ein bisschen ;-)
      </p>
      <p class="caption-img">
        <span class="caption">Dein</span>
        <img
          width="300"
          src="../../assets/days/2017/01/car.jpg"
          alt=""
        >
      </p>
      <hr>
      <Gallery directory="2017/01" />
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'

export default {
  name: 'Day01',
  components: {
    Gallery,
    Day
  }
}
</script>

<style lang="scss">
  .caption-img {
    margin: 0 auto;
    width: 50%;
    position: relative;

    span.caption {
      position: absolute;
      top: 65px;
      left: 25px;
      font-size: 18px;
      font-weight: bold;
    }

    @media all and (max-width: 700px) {
      width: 70%;
    }

    @media all and (max-width: 500px) {
      width: 100%;
    }
  }
</style>
