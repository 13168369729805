<template>
  <Day day="24.12.2017">
    <div slot="page-content">
      <Voucher :voucher-active="buttonClicked">
        <div slot="voucherActivator">
          <h2>
            Bitte Ton anmachen
          </h2>
          <p>
            <button @click="buttonClicked = true">
              <h2>Start</h2>
            </button>
          </p>
        </div>

        <div slot="voucher">
          <p>
            <iframe
              style="display: none"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/KCdsmiiunRY?rel=0&controls=0&showinfo=0&autoplay=1"
              frameborder="0"
              gesture="media"
              allow="encrypted-media"
              allowfullscreen
            />
          </p>
        </div>
      </Voucher>
    </div>
    <div id="replacement">
      <img src="../../assets/gutschein-hp/harry-potter-and-the-chamber-of-secrets.jpg">
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'
import Voucher from '../Voucher.vue'

export default {
  name: 'Day24',
  components: {
    Day,
    Voucher
  },
  data () {
    return {
      buttonClicked: false,
      slowlyFadeIn: 'opacity: 0'
    }
  },
  watch: {
    buttonClicked: function () {
      if (this.buttonClicked === true) {
        document.getElementsByClassName('background')[0].innerHTML = ''
        document.getElementsByClassName('content')[0].style = 'opacity: 0'
        setTimeout(this.fadeIn, 2000)
      }
    }
  },
  methods: {
    fadeIn: function () {
      let op = 0

      let imgSrc = require('../../assets/gutschein-hp/harry-potter-and-the-chamber-of-secrets.jpg')
      let template = '<img class="background-img" src="{src}" style="{style}">'.replace('{src}', imgSrc)

      let background = document.getElementsByClassName('background')[0]

      let timer = setInterval(() => {
        if (op >= 1.0) {
          clearInterval(timer)
        }

        op += 0.0025
        this.slowlyFadeIn = 'opacity: ' + op

        background.innerHTML = template.replace('{style}', this.slowlyFadeIn)
      }, 300)
    }
  }
}
</script>

<style>
  .background-img {
    max-width: 100%;
  }
</style>
