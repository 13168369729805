<template>
  <div>
    <h1>Bitte Passwort eingeben</h1>
    <div class="form">
      <div
        class="error"
        v-if="error"
      >
        Es ist ein Fehler aufgetreten. Bitte versuche es erneut!
      </div>
      <input
        type="password"
        @keyup.enter="checkLogin"
        v-model="pw"
      >
      <button
        type="submit"
        @click="checkLogin"
      >
        Login
      </button>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'

  export default {
    name: 'Login',
    data () {
      return {
        pw: '',
        error: false
      }
    },
    methods: {
      checkLogin: function () {
        if (this.pw === 'Wunderbärchen') {
          const expiryDate = new Date()
          expiryDate.setMonth(expiryDate.getMonth() + 2)

          Cookies.set('REMEMBERME', true, {expires: expiryDate})
          this.error = false
          this.$router.replace({path: '/2017/'})
        } else if (this.pw === 'Give it to me!') {
          const expiryDate = new Date()
          expiryDate.setMonth(expiryDate.getMonth() + 2)

          Cookies.set('REMEMBERME2018', true, {expires: expiryDate})
          this.error = false
          this.$router.replace({path: '/2018/'})
        } else if (this.pw === '2019') {
          const expiryDate = new Date()
          expiryDate.setMonth(expiryDate.getMonth() + 2)

          Cookies.set('REMEMBERME2019', true, {expires: expiryDate})
          this.error = false
          this.$router.replace({path: '/2019/'})
        } else if (this.pw === 'Berlin2024') {
          const expiryDate = new Date()
          expiryDate.setMonth(expiryDate.getMonth() + 2)

          Cookies.set('REMEMBERME2024', true, {expires: expiryDate})
          this.error = false
          this.$router.replace({path: '/2024/'})
        } else {
          this.error = true
        }
      }
    }
  }
</script>

<style lang="scss">
  .form {
    width: 50%;
    margin: 0 auto;
    flex-flow: column;
    justify-content: center;
    text-align: center;

    background: rgba(#000, 0.3);
    padding: 25px;

    @media screen and (max-width: 900px) {
      width: 75%;
    }

    @media screen and (max-width: 400px) {
      width: 90%;
    }

    input, button {
      opacity: 0.9;
      padding: 10px;
      background: rgba(#fff, 0.3);
      color: white;
      border: 1px solid black;
    }

    button {
      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
