<template>
  <Day :day="formattedDate">
    <div slot="page-content">
      <Challenge
        :title="title"
        :description="description"
        :position-link="positionLink"
        :position-img="positionImg"
      />
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'
import challenges from './challenges.json'
import Moment from 'moment'
import Challenge from './Challenge'

const imageContext = require.context('../../assets/2019', false, /\.(jpg|png)$/)

export default {
  name: 'ChallengeFactory',
  components: {
    Challenge,
    Day
  },
  computed: {
    day: function () {
      return this.$route.params['day']
    },
    year: function () {
      return this.$route.params['year']
    },
    title: function () {
      return challenges[this.year].title[this.day]
    },
    positionImg: function () {
      let path = challenges[this.year].positionImg[this.day]
      if (this.year === '2019') {
        return imageContext(path)
      }

      return path
    },
    positionLink: function () {
      return challenges[this.year].positionLink[this.day]
    },
    description: function () {
      return challenges[this.year].description[this.day]
    },
    formattedDate: function () {
      let date = new Moment(this.day + '.12.' + this.year, 'DD.MM.YYYY')
      return date.format('DD.MM.YYYY')
    }
  }
}
</script>
