<template>
  <Day day="12.12.2017">
    <div slot="page-content">
      <h2>Wir haben ganz schön viel Urlaub zu kalten Jahreszeiten gemacht!</h2>
      <p class="center">
        Da muss man seine kalten Händchen aber gut einpacken und ...
      </p>
      <Gallery directory="2017/12" />
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'

export default {
  name: 'Day12',
  components: {
    Gallery,
    Day
  }
}
</script>
