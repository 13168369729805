<template xmlns:v-img="http://www.w3.org/1999/xhtml">
  <div class="gallery">
    <div class="images">
      <img
        v-for="img in images"
        :key="img"
        class="thumbnail"
        :src="getPath(img)"
        alt=""
        v-img:gallery
      >
    </div>
  </div>
</template>

<script>
var imageContext = require.context('@/assets/days', true, /\.(jpg|png|gif)$/)

export default {
  name: 'Gallery',
  props: ['directory'],
  methods: {
    getPath: function (path) {
      return imageContext(path)
    }
  },
  computed: {
    images: function () {
      let imageArray = []

      imageContext.keys().forEach(key => {
        if (key.indexOf('./' + this.directory) === 0) {
          imageArray.push(key)
        }
      })

      return imageArray
    }
  }
}
</script>

<style lang="scss">
  .gallery {
    width: 600px;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
  }

  .images {
    max-width: 650px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    @media screen and (max-width: 700px) {
      max-width: 600px;
    }
    @media screen and (max-width: 600px) {
      max-width: 550px;
    }
    @media screen and (max-width: 500px) {
      max-width: 475px;
    }
    @media screen and (max-width: 400px) {
      max-width: 350px;
    }
  }

  img.thumbnail {
    max-height: 100px;
    width: auto;
  }
</style>
