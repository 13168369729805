<template>
  <div>
    <h2>{{ quizData.question }}</h2>
    <ul class="answers">
      <template v-for="(answer, key) in quizData.answers">
        <li
          :key="key"
          :class="{ correct: isAnsweredCorrectly(key), wrong: isAnsweredIncorrectly(key) }"
          @click="checkAnswer(key)"
        >
          {{ answer.answer }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Quiz',
  props: ['data'],
  data () {
    let userAnswers = []
    this.data.answers.forEach((answer, key) => {
      userAnswers.push({key: key, answered: false})
    })

    return {
      quizData: this.data,
      userAnswers: userAnswers,
      answeredAllCorrectly: false
    }
  },
  methods: {
    checkAnswer: function (key) {
      this.userAnswers[key].answered = true

      this.checkUserAnswers()
    },
    checkUserAnswers: function () {
      let correctAnswers = this.quizData.answers.filter(answer => {
        return answer.correct
      })

      let correctUserAnswers = this.userAnswers.filter(answer => {
        return this.quizData.answers[answer.key].correct === true && answer.answered
      })

      if (correctAnswers.length === correctUserAnswers.length) {
        this.answeredAllCorrectly = true
        this.$emit('finished')
      }
    },
    isAnsweredCorrectly: function (key) {
      return !!(this.userAnswers[key].answered && this.quizData.answers[key].correct)
    },
    isAnsweredIncorrectly: function (key) {
      return (this.userAnswers[key].answered && !this.quizData.answers[key].correct)
    }
  }
}
</script>

<style lang="scss">
  .answers {
    width: 400px;
    display: block;
    margin: 0 auto;
    list-style: inside;
    padding: 0;

    @media all and (max-width: 600px) {
      width: 100%;
    }

    li {
      padding: 20px 10px 20px 30px;
      background: rgba(255,255,255,0.3);
      margin: 10px 0;

      &:hover {
        cursor: pointer;
      }

      &.correct {
        background: rgba(0, 255, 0, 0.3);
      }

      &.wrong {
        background: rgba(255, 0, 0, 0.3);
      }
    }
  }
</style>
