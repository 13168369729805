<template>
  <div>
    <div
      class="voucher"
      v-if="voucherActive"
    >
      <slot name="voucher" />
    </div>
    <div
      class="voucher"
      v-else
    >
      <slot name="voucherActivator" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Voucher',
    props: ['voucherActive']
  }
</script>

<style lang="scss">
  .voucher {
    p {
      font-style: italic;
      font-weight: bold;
      text-align: center;
    }
  }
</style>
