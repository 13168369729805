<template>
  <div id="app">
    <div class="background">
      <video
        autoplay
        loop
        id="video-background"
        muted
        plays-inline
      >
        <source
          :src="video.src"
          :type="video.type"
        >
      </video>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      sources: [
        {
          src: require('./assets/snow1.webm'),
          type: 'video/webm'
        },
        {
          src: require('./assets/snow2.webm'),
          type: 'video/webm'
        },
        {
          src: require('./assets/snow3.webm'),
          type: 'video/webm'
        },
        {
          src: require('./assets/particles.webm'),
          type: 'video/webm'
        },
        {
          src: require('./assets/fireplace.webm'),
          type: 'video/webm'
        }
      ]
    }
  },
  computed: {
    video: function () {
      if (this.$route.params.year == '2024' || this.$route.name == 'Main2024') {
        return this.sources[4]
      }

      return this.sources[Math.floor(Math.random() * 4)]
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Handlee|Montserrat:300,400');

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  background: #222;
  margin: 0;
  width: 100%;
  max-width: 100%;

  font-size: 100%;
}

a {
  color: white;
  outline: none;
}

h1 {
  font-size: 6rem;
  text-shadow: 0 2px 8px #666;
  margin: 50px;

  @media screen and (max-width: 900px) {
    font-size: 3rem;
    margin: 30px;
  }

  @media screen and (max-width: 400px) {
    font-size: 2rem;
    margin: 25px;
  }
}

h1, h2 {
  font-family: 'Handlee', cursive;
  text-align: center;
}

#video-background {
  /*  making the video fullscreen  */
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
}
</style>
