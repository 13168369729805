import Cookies from 'js-cookie'
import Vue from 'vue'
import Router from 'vue-router'
import Main2017 from "../components/Main2017.vue"
import Main2018 from '../components/Main2018.vue'
import Main2019 from '../components/Main2019.vue'
import Main2024 from "../components/Main2024.vue"

import Login from '../components/Login.vue'
import Day01 from '../components/days/Day01.vue'
import Day02 from '../components/days/Day02.vue'
import Day03 from '../components/days/Day03.vue'
import Day04 from '../components/days/Day04.vue'
import Day05 from '../components/days/Day05.vue'
import Day06 from '../components/days/Day06.vue'
import Day07 from '../components/days/Day07.vue'
import Day08 from '../components/days/Day08.vue'
import Day09 from '../components/days/Day09.vue'
import Day10 from '../components/days/Day10.vue'
import Day11 from '../components/days/Day11.vue'
import Day12 from '../components/days/Day12.vue'
import Day13 from '../components/days/Day13.vue'
import Day14 from '../components/days/Day14.vue'
import Day15 from '../components/days/Day15.vue'
import Day16 from '../components/days/Day16.vue'
import Day17 from '../components/days/Day17.vue'
import Day18 from '../components/days/Day18.vue'
import Day19 from '../components/days/Day19.vue'
import Day20 from '../components/days/Day20.vue'
import Day21 from '../components/days/Day21.vue'
import Day22 from '../components/days/Day22.vue'
import Day23 from '../components/days/Day23.vue'
import Day24 from '../components/days/Day24.vue'

import ChallengeFactory from '../components/challenges/ChallengeFactory.vue'
import DayFactory from "../components/challenges/DayFactory.vue"

let days = [
  Day01, Day02, Day03, Day04, Day05, Day06, Day07, Day08, Day09, Day10, Day11, Day12,
  Day13, Day14, Day15, Day16, Day17, Day18, Day19, Day20, Day21, Day22, Day23, Day24
]

Vue.use(Router)

let routings = [
  {
    path: '/2017/',
    component: Main2017
  },
  {
    path: '/2018/',
    component: Main2018
  },
  {
    path: '/2019/',
    component: Main2019
  },
  {
    path: '/2024/',
    name: 'Main2024',
    component: Main2024
  },
  {
    path: '/login',
    component: Login
  }
]

for (let i = 1; i <= days.length; i++) {
  routings.push({
    path: '/2017/day/' + i,
    component: days[i - 1]
  })
}

routings.push({
  path: '/:year/day/:day',
  beforeEnter: (to, from, next) => {
    if (to.params.year === '2024') {
      to.matched[0].components.default = DayFactory;
    } else {
      to.matched[0].components.default = ChallengeFactory;
    }
    next();
  }
})

let router = new Router({
  routes: routings
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  }

  var allowedToPass2017 = Cookies.get('REMEMBERME') && to.path.includes('2017')
  var allowedToPass2018 = Cookies.get('REMEMBERME2018') && to.path.includes('2018')
  var allowedToPass2019 = Cookies.get('REMEMBERME2019') && to.path.includes('2019')
  var allowedToPass2024 = Cookies.get('REMEMBERME2024') && to.path.includes('2024')

  if (allowedToPass2017 || allowedToPass2018 || allowedToPass2019 || allowedToPass2024) {
    next()
  } else {
    next('/login')
  }
})

export default router
