<template>
  <div>
    <h1>Merry Christmas!</h1>
    <h2>For the 5th time &hearts;</h2>
    <div class="cards-wrapper">
      <ul class="cards">
        <li
          class="card"
          v-for="i in range(1,24)"
          :key="i"
        >
          <div>
            <router-link :to="'/2019/day/' + i">
              {{ i }}
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Main2019',
  methods: {
    range: function (min, max) {
      let arr = []
      let j = 0
      for (let i = min; i <= max; i++) {
        arr[j++] = i
      }

      return arr
    }
  }
}
</script>

<style lang="scss">
  .cards-wrapper {
    width: 1200px;
    height: 600px;
    margin: 0 auto;
    max-width: 100%;
  }

  .cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @media screen and (max-width: 385px) {
      justify-content: center;
    }
  }

  .card {
    width: 100px;
    height: 100px;

    border-radius: 25px;

    margin: 35px;

    background: rgba(#000, 0.1);
    border: 1px solid #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.5s;

    &:hover {
      cursor: pointer;
      background: rgba(#000, 0.3);
      box-shadow: 0 0 20px rgba(#fff ,0.3);

      &:nth-child(1)  { background: rgba(#56C9FB, 0.3); }
      &:nth-child(2)  { background: rgba(#56FBBC, 0.3); }
      &:nth-child(3)  { background: rgba(#FB9856, 0.3); }
      &:nth-child(4)  { background: rgba(#7A56FB, 0.3); }
      &:nth-child(5)  { background: rgba(#FB56CC, 0.3); }
      &:nth-child(6)  { background: rgba(#EF0606, 0.3); }
      &:nth-child(7)  { background: rgba(#970202, 0.3); }
      &:nth-child(8)  { background: rgba(#229702, 0.3); }
      &:nth-child(9)  { background: rgba(#029760, 0.3); }
      &:nth-child(10) { background: rgba(#026A97, 0.3); }
      &:nth-child(11) { background: rgba(#0F0297, 0.3); }
      &:nth-child(12) { background: rgba(#FED5D2, 0.3); }
      &:nth-child(13) { background: rgba(#FEF7D2, 0.3); }
      &:nth-child(14) { background: rgba(#D2FEDC, 0.3); }
      &:nth-child(15) { background: rgba(#D2EBFE, 0.3); }
      &:nth-child(16) { background: rgba(#E3D2FE, 0.3); }
      &:nth-child(17) { background: rgba(#56C9FB, 0.3); }
      &:nth-child(18) { background: rgba(#56FBBC, 0.3); }
      &:nth-child(19) { background: rgba(#FB9856, 0.3); }
      &:nth-child(20) { background: rgba(#7A56FB, 0.3); }
      &:nth-child(21) { background: rgba(#FB56CC, 0.3); }
      &:nth-child(22) { background: rgba(#EF0606, 0.3); }
      &:nth-child(23) { background: rgba(#970202, 0.3); }
      &:nth-child(24) { background: rgba(#229702, 0.3); }

    }

    a {
      outline: 0;
      color: #fff;
      text-decoration: none;

      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:active,
      &:focus {
        border: none;
      }
    }

    div {
      font-family: 'Handlee', cursive;
      font-size: 52px;
      font-weight: bold;
      padding-top: 5px;

      width: 100%;
      height: 100%;
    }
  }
</style>
