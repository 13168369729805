<template>
  <Day day="23.12.2017">
    <div slot="page-content">
      <h2>Ich wollte dir einfach nochmal sagen:</h2>
      <h1>Ich lieb dich, Jana</h1>
      <p class="center">
        <img
          src="../../assets/days/2017/23/IMG_20150610_223748.jpg"
          class="big"
          v-img
        >
      </p>
      <hr>
      <Gallery directory="2017/23" />
      <hr>
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'

export default {
  name: 'Day23',
  components: {
    Gallery,
    Day
  }
}
</script>
