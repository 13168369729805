<template>
  <Day day="18.12.2017">
    <div slot="page-content">
      <h2>Gutschein</h2>
      <p class="center">
        für meine kleine Naschkatze
      </p>
      <p class="center">
        <img
          src="../../assets/days/2017/18/IMG_20150610_214645-ANIMATION.gif"
          class="big"
          v-img
        >
      </p>
      <hr>
      <Gallery directory="2017/18" />
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'

export default {
  name: 'Day18',
  components: {
    Gallery,
    Day
  }
}
</script>
