<template>
  <Day day="08.12.2017">
    <div slot="page-content">
      <h2>Heute ein kleines Bilderrätsel</h2>
      <Voucher :voucher-active="quizFinished">
        <div slot="voucherActivator">
          <p>
            <img
              class="big"
              src="../../assets/days/2017/08/IMG_20170101_000635.jpg"
              v-img
            >
          </p>
          <Quiz
            :data="quizData"
            @finished="quizFinished = true"
          />
        </div>

        <div slot="voucher">
          <h2>Sehr gut! Dann darfst du jetzt deine Eule öffnen!</h2>
          <p>
            <img
              class="big"
              src="../../assets/days/2017/08/IMG_20170101_000804-ANIMATION.gif"
              v-img
            >
          </p>
          <hr>
          <p>
            <video
              class="big"
              muted
              autoplay
              loop
              onloadedmetadata="this.muted = true"
            >
              <source
                src="../../assets/days/2017/08/VID_20170101_000230.mp4"
                type="video/mp4"
              >
            </video>
          </p>
        </div>
      </Voucher>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'
import Voucher from '../Voucher.vue'
import Quiz from '../Quiz.vue'

export default {
  name: 'Day08',
  data: function () {
    return {
      quizData: {
        question: 'Was zeigt dieses Bild?',
        answers: [
          {answer: 'Hochhäuser', correct: true},
          {answer: 'Berlin', correct: true},
          {answer: 'Feuerwerk', correct: true},
          {answer: 'Straßenlaternen', correct: true},
          {answer: 'Silvester', correct: true}
        ]
      },
      quizFinished: false
    }
  },
  components: {
    Day,
    Voucher,
    Quiz
  }
}
</script>

<style lang="scss" scoped>
  button {
    padding: 25px 50px 20px;
    background: rgba(#fff, 0.3);
    color: white;
    border: 3px solid white;
    border-radius: 20px;

    &:hover {
      cursor: pointer;
    }
  }
</style>
