<template>
  <Day day="05.12.2017">
    <div slot="page-content">
      <Voucher :voucher-active="quizFinished">
        <div slot="voucherActivator">
          <h3 style="text-align:center;">
            Wir haben auch schon so manchen Film <br> zusammen gesehen!
          </h3>
          <p>Deshalb gibt es noch ein kleines Rätsel für Dich:</p>
          <hr>
          <Quiz
            :data="quizData"
            @finished="quizFinished = true"
          />
        </div>

        <div slot="voucher">
          <h2>Du bist ja eine richtige Film-Expertin! :-)</h2>
          <hr>
          <h2>Gutschein</h2>
          <p>
            <img
              src="../../assets/days/2017/05/IMG_20160830_213216.jpg"
              class="thumbnail"
              v-img
            >
          </p>
          <p>
            für einen richtig tollen Film im Kino... und wenn er doof ist, haben wir wenigstens noch Popcorn!
          </p>
        </div>
      </Voucher>
      <hr>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'
import Quiz from '../Quiz.vue'
import Voucher from '../Voucher.vue'

export default {
  name: 'Day05',
  data: function () {
    return {
      voucherClicked: true,
      quizData: {
        question: 'Welcher Film gehört nicht zu meinen Lieblingsfilmen?',
        answers: [
          {answer: 'Die Glückritter', correct: false},
          {answer: 'Lost in Translation', correct: false},
          {answer: 'Millennium Actress', correct: false},
          {answer: 'Inception', correct: false},
          {answer: 'Iron Man', correct: true},
          {answer: 'The Fast and the Furious: Tokyo Drift', correct: false},
          {answer: 'Jeff, who lives at home', correct: false}
        ]
      },
      quizFinished: false
    }
  },
  components: {
    Day,
    Quiz,
    Voucher
  }
}
</script>
