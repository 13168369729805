<template>
  <Day day="04.12.2017">
    <div slot="page-content">
      <div class="countdown">
        <Countdown deadline="July 28, 2018 20:30" />
      </div>
      <Voucher :voucher-active="quizFinished">
        <div slot="voucherActivator">
          <h3 style="text-align:center;">
            Heute gibt es ein kleines Rätsel:
          </h3>
          <Quiz
            :data="quizData"
            @finished="quizFinished = true"
          />
        </div>

        <div slot="voucher">
          <h2>Wow! Was du so alles weißt :-) Hier ist deine Belohnung:</h2>
          <hr>
          <h2>Gutschein</h2>
          <p>
            <video
              src="../../assets/days/2017/04/penguins.mp4"
              autoplay
              loop
              playsinline
            />
          </p>
          <p>
            für bis zu 3 Teilnahmeplätze an der
            <a
              href="https://www.berlin-citynight.de/"
              target="_blank"
            >27. adidas Runners City Night</a>
          </p>
        </div>
      </Voucher>

      <hr>
      <h2>Außerdem... Katzen!</h2>
      <Gallery directory="2017/04" />
      <div class="small-video">
        <video
          muted
          autoplay
          loop
          onloadedmetadata="this.muted = true"
        >
          <source
            src="../../assets/days/2017/04/VID_20160615_091654.mp4"
            type="video/mp4"
          >
        </video>
      </div>
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'
import Countdown from 'vuejs-countdown'
import Quiz from '../Quiz.vue'
import Voucher from '../Voucher.vue'

export default {
  data: function () {
    return {
      quizData: {
        question: 'Was findet am Samstag, den 28. Juli 2018 in Berlin statt?',
        answers: [
          {answer: 'Die Kelly Family spielt in der Waldbühne', correct: true},
          {answer: 'Christopher Street Day 2018', correct: true},
          {answer: 'Totale Mondfinsternis - After Party', correct: true},
          {answer: '27. adidas Runners City Night', correct: true}
        ]
      },
      quizFinished: false
    }
  },
  components: {
    Gallery,
    Day,
    Countdown,
    Quiz,
    Voucher
  }
}
</script>

<style>
  .countdown {
    display: flex;
    justify-content: center;
    margin-bottom: 75px;
  }
</style>
