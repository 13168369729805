<template>
  <Day day="14.12.2017">
    <div slot="page-content">
      <h2>Also ich hab genug von der Kälte!</h2>
      <p class="center">
        Heute mal ein paar Bilder zum innerlichen Aufwärmen
      </p>
      <hr>
      <Gallery directory="2017/14" />
      <div class="small-video">
        <video
          muted
          autoplay
          loop
          onloadedmetadata="this.muted = true"
        >
          <source
            src="../../assets/days/2017/14/VID_20170905_191735.mp4"
            type="video/mp4"
          >
        </video>
      </div>
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'

export default {
  name: 'Day14',
  components: {
    Gallery,
    Day
  }
}
</script>
