<template>
  <Day day="09.12.2017">
    <div slot="page-content">
      <h2>Manchmal muss man auch einfach durchatmen und die Natur genießen</h2>
      <hr>
      <p>
        <Gallery directory="2017/09" />
      </p>
      <hr>
      <p class="center">
        <video
          class="big"
          muted
          autoplay
          loop
          onloadedmetadata="this.muted = true"
        >
          <source
            src="../../assets/days/2017/09/VID_20170907_180539.mp4"
            type="video/mp4"
          >
        </video>
      </p>
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'

export default {
  name: 'Day09',
  components: {
    Gallery,
    Day
  }
}
</script>
