<template>
  <Day day="22.12.2017">
    <div slot="page-content">
      <h2>Guten Flug! ♥</h2>
      <p class="center">
        Noch ein letztes kleines Rätsel:
      </p>
      <hr>
      <Voucher :voucher-active="quizFinished">
        <div slot="voucherActivator">
          <p>
            <img
              src="../../assets/days/2017/22/IMG_20170525_130748.jpg"
              class="big"
              v-img
            >
          </p>
          <Quiz
            :data="quizData"
            @finished="quizFinished = true"
          />
        </div>

        <div slot="voucher">
          <h2>Das Hotel mit dem wohl besten Frühstück</h2>
          <p>Aber eine Sache fehlte noch in unserem Zimmer, um es perfekt zu machen...</p>
          <Gallery directory="2017/22" />
        </div>
      </Voucher>
      <hr>
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'
import Voucher from '../Voucher.vue'
import Quiz from '../Quiz.vue'

export default {
  name: 'Day22',
  components: {
    Gallery,
    Day,
    Quiz,
    Voucher
  },
  data () {
    return {
      voucherClicked: true,
      quizData: {
        question: 'Wieviele Einwohner hat die Stadt, in der das Foto gemacht wurde?',
        answers: [
          {answer: '52.000', correct: false},
          {answer: '18.500', correct: false},
          {answer: '35.000', correct: true},
          {answer: '26.000', correct: false},
          {answer: '60.000', correct: false}
        ]
      },
      quizFinished: false
    }
  }
}
</script>
