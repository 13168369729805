<template>
  <Day day="11.12.2017">
    <div slot="page-content">
      <h2>Apropos kalt...</h2>
      <Gallery directory="2017/11" />
      <p class="center">
        Erinnerst Du Dich noch daran, wie bretthart deine Oberschenkel nach dem Skifahren waren? <br>
        Vielleicht hab ich  da was für dich!
      </p>
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'

export default {
  name: 'Day11',
  components: {
    Gallery,
    Day
  }
}
</script>
