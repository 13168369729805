<template>
  <Day day="13.12.2017">
    <div slot="page-content">
      <Voucher :voucher-active="quizFinished">
        <div slot="voucherActivator">
          <p>
            <img
              src="../../assets/days/2017/13/IMG_20150310_135945.jpg"
              class="big"
              v-img
            >
          </p>
          <Quiz
            :data="quizData"
            @finished="quizFinished = true"
          />
        </div>

        <div slot="voucher">
          <h2>Wow, gleich beim ersten Mal?</h2>
          <p>Die Frage nach Stadt oder Land wäre ja auch zu leicht gewesen, oder?</p>
          <hr>
          <Gallery directory="2017/13" />
        </div>
      </Voucher>
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'
import Voucher from '../Voucher.vue'
import Quiz from '../Quiz.vue'

export default {
  name: 'Day13',
  components: {
    Gallery,
    Day,
    Voucher,
    Quiz
  },
  data () {
    return {
      voucherClicked: true,
      quizData: {
        question: 'In welche Himmelsrichtung wurde dieses Bild aufgenommen?',
        answers: [
          {answer: 'Süd-Westen', correct: false},
          {answer: 'Norden', correct: false},
          {answer: 'Nord-Westen', correct: false},
          {answer: 'Süd-Osten', correct: true},
          {answer: 'Westen', correct: false}
        ]
      },
      quizFinished: false
    }
  }
}
</script>
