<template>
  <Day day="19.12.2017">
    <div slot="page-content">
      <Voucher :voucher-active="quizFinished">
        <div slot="voucherActivator">
          <p>
            <img
              src="../../assets/days/2017/19/IMG_20160820_201513.jpg"
              class="big"
              v-img
            >
          </p>
          <Quiz
            :data="quizData"
            @finished="quizFinished = true"
          />
        </div>

        <div slot="voucher">
          <h2>100 Punkte!</h2>
          <p>♥ Das war ein richtig schönes verlängertes Wochenende ♥</p>
          <Gallery directory="2017/19" />
        </div>
      </Voucher>
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'
import Voucher from '../Voucher.vue'
import Quiz from '../Quiz.vue'

export default {
  name: 'Day19',
  components: {
    Gallery,
    Day,
    Voucher,
    Quiz
  },
  data () {
    return {
      voucherClicked: true,
      quizData: {
        question: 'In welcher Stadt wurde dieses Bild aufgenommen?',
        answers: [
          {answer: 'Hamburg', correct: false},
          {answer: 'Wernigerode', correct: false},
          {answer: 'Nähe Hof', correct: false},
          {answer: 'Kopenhagen', correct: false},
          {answer: 'Bamberg', correct: true}
        ]
      },
      quizFinished: false
    }
  }
}
</script>
