<template>
  <Day day="06.12.2017">
    <div slot="page-content">
      <h2>Ho ho ho!</h2>
      <p style="text-align:center;">
        Der Nikolaus hat mir etwas für Dich mitgegeben, aber du musst erst folgende Frage beantworten:
      </p>
      <hr>
      <Voucher :voucher-active="quizFinished">
        <div slot="voucherActivator">
          <p>
            <img
              src="../../assets/days/2017/06/IMG_20150612_171639.jpg"
              class="thumbnail"
              v-img
            >
          </p>
          <Quiz
            :data="quizData"
            @finished="quizFinished = true"
          />
        </div>

        <div slot="voucher">
          <h2>Richtig! Und es war so schöööön :-) </h2>
          <Gallery directory="2017/06" />
        </div>
      </Voucher>
      <hr>
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'
import Quiz from '../Quiz.vue'
import Voucher from '../Voucher.vue'

export default {
  name: 'Day06',
  data: function () {
    return {
      voucherClicked: true,
      quizData: {
        question: 'In welcher Stadt wurde dieses Bild aufgenommen?',
        answers: [
          {answer: 'Hamburg', correct: false},
          {answer: 'Berlin', correct: false},
          {answer: 'Barcelona', correct: false},
          {answer: 'Kopenhagen', correct: true},
          {answer: 'Bamberg', correct: false}
        ]
      },
      quizFinished: false
    }
  },
  components: {
    Gallery,
    Day,
    Quiz,
    Voucher
  }
}
</script>
