<template>
  <Day day="21.12.2017">
    <div slot="page-content">
      <h2>Wir haben schon richtig viele tolle Sachen zusammen gegessen</h2>
      <p class="center">
        ... und gekocht! ;-)<br>
        ... und irgendwie erstaunlich viele Burger fotografiert :-D
      </p>

      <hr>
      <Voucher :voucher-active="quizFinished">
        <div slot="voucherActivator">
          <p>
            <img
              src="../../assets/days/2017/21/IMG_20170304_200728.jpg"
              class="big"
              v-img
            >
          </p>
          <Quiz
            :data="quizData"
            @finished="quizFinished = true"
          />
        </div>

        <div slot="voucher">
          <h2>Gutschein</h2>
          <p>
            <img
              src="../../assets/gutschein-kochen/IMG_20160227_201224.jpg"
              class="thumbnail"
              v-img
            >
          </p>
          <p>Für ein ganz ganz leckeres Essen - bei "Chez Mo" oder einem anderen Restaurant ;-)</p>
          <Gallery directory="2017/21" />
        </div>
      </Voucher>
      <hr>
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'
import Voucher from '../Voucher.vue'
import Quiz from '../Quiz.vue'

export default {
  name: 'Day21',
  components: {
    Gallery,
    Day,
    Quiz,
    Voucher
  },
  data () {
    return {
      voucherClicked: true,
      quizData: {
        question: 'Weißt du noch, wo wir das gegessen haben?',
        answers: [
          {answer: 'Glasgow', correct: false},
          {answer: 'Edinburgh', correct: false},
          {answer: 'Erlangen', correct: false},
          {answer: 'Stockholm', correct: true},
          {answer: 'Berlin', correct: false},
          {answer: 'Bamberg', correct: false}
        ]
      },
      quizFinished: false
    }
  }
}
</script>
