<template>
  <Day day="02.12.2017">
    <div slot="page-content">
      <h3>Liebe Jana,</h3>
      <p>
        wir haben auch schon so manches Stück Kuchen zusammen gegessen. Aber wie das so ist bei Kuchen...
        irgendwann ist er leider weg :-(
      </p>
      <p style="text-align: center">
        <img
          src="../../assets/days/2017/02/IMG-20150730-WA0001.jpg"
          class="thumbnail"
          v-img
        >
      </p>
      <hr>
      <Voucher :voucher-active="voucherClicked">
        <div slot="voucherActivator">
          <p>
            <button @click="activateVoucher">
              Hier klicken!
            </button>
          </p>
        </div>

        <div slot="voucher">
          <h2>Gutschein</h2>
          <p>
            <img
              src="../../assets/days/2017/02/IMG-20150730-WA0000.jpg"
              class="thumbnail"
              v-img
            >
          </p>
          <p>
            für einen richtig tollen Kuchen von Stefan <br>
            oder einer Konditorei deiner Wahl
          </p>
        </div>
      </Voucher>
      <hr>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'
import Voucher from '../Voucher.vue'

export default {
  name: 'Day02',
  data () {
    return {
      voucherClicked: false
    }
  },
  methods: {
    activateVoucher: function () {
      this.voucherClicked = true
    }
  },
  components: {
    Day, Voucher
  }
}
</script>
