<template>
  <Day day="03.12.2017">
    <div slot="page-content">
      <h3>...und ich wollt Dir nur mal sagen:</h3>
      <br>
      <h2>
        Ich find Dich ziemlich süß!
      </h2>
      <hr>
      <Gallery directory="2017/03" />
    </div>
  </Day>
</template>

<script>
import Gallery from '../Gallery.vue'
import Day from '../Day.vue'

export default {
  name: 'Day03',
  components: {
    Gallery,
    Day
  }
}
</script>
